.header {
    background-color: #fff;
    height: 100px;
    display: flex;
    align-items: center;
    .logo {
        text-align: center;
        img {
            width: 123px;
        }
    }
    &.fixed-tab {
        position: sticky;
        left: 0;
        right: 0;
        top: 0;
        transition: all .3s;
        background: #fff;
        box-shadow: 0 5px 10px #00000029;
        z-index: 1050;
    }
}

@media screen and (max-width: 767px) {
    .header {
        height: auto;
        padding: 20px 0;
        .logo {
            img {
                width: 135px;
            }
        }
    }
}
.registration-section {
    background-color: #F2F2F2;
    padding: 42px 0;

    .registration-text {
        text-align: center;

        h2 {
            font-family: Co Headline Bold;
            color: #0C646E;
            font-size: 28px;
            line-height: 50px;
            margin-bottom: 0;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            letter-spacing: normal;
            padding: 0px 0 30px;
        }
    }

    .registration-section-inner {
        width: 39%;
        margin: 0 auto;

        .form-group {
            margin-bottom: 18px;

            .form-control {
                border-radius: 52px;
                border: 1px solid #959595;
                height: 50px;
                padding: 1rem 2rem;
                box-shadow: 0px 3px 6px #00000029;
            }

            select {

                &#gender {
                    border-radius: 52px;
                    border: 1px solid #959595;
                    height: 50px;
                    // padding: 0.5rem 1.8rem;
                    box-shadow: 0px 3px 6px #00000029;
                    width: 100%;
                    color: #686868;
                    font-size: 1rem;
                    outline: 0;
                    background-color: #fff;

                    &.custom-select::-ms-expand {
                        display: none;
                    }

                    &.custom-select {
                        appearance: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        background-image: url('../images/arrow.svg');
                        background-position: right;
                        background-repeat: no-repeat;
                        background-position-x: 95%;
                        padding: 0 30px;
                        -webkit-touch-callout: none
                    }

                    @supports (-webkit-touch-callout: none) {
                        .custom-select {
                            padding-right: 30px;
                        }
                    }
                }
            }

            select#nationality {
                border-radius: 52px;
                border: 1px solid #959595;
                height: 50px;
                padding: 0.5rem 1.8rem;
                box-shadow: 0px 3px 6px #00000029;
                width: 100%;
                color: #686868;
                font-size: 1rem;
                outline: 0;
            }

            select#ethnicity {
                border-radius: 52px;
                border: 1px solid #959595;
                height: 50px;
                padding: 0.5rem 1.8rem;
                box-shadow: 0px 3px 6px #00000029;
                width: 100%;
                color: #686868;
                font-size: 1rem;
                outline: 0;
            }
        }

        .yes-ready-btn {
            width: 100%;
            text-align: center;
            margin-top: 45px;

            button {
                background-color: #0C646E;
                width: 100%;
                height: 60px;
                border-radius: 50px;
                font-family: Lato-Bold;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #FFFFFF;
                border: 0px;
                box-shadow: 0px 3px 6px #00000029;

                &:focus {
                    box-shadow: none;
                }
            }
        }

        .error-message {
            color: red;
            font-size: 13px;
            padding-top: 1px;
        }
    }
}


@media screen and (max-width: 767px) {
    .registration-section {
        padding: 26px 0 48px;

        .registration-text {
            h2 {
                font-size: 21px;
            }
        }

        .registration-section-inner {
            width: 100%;

            .yes-ready-btn {
                margin-top: 30px;
            }
        }
    }
}
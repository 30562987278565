@font-face {
    font-family: Co Headline Bold;
    src: url("../../../public/Co Headline Bold.otf");
}

@font-face {
    font-family: Co Headline;
    src: url("../../../public/Co Headline.otf");
}

@font-face {
    font-family: Lato-Regular;
    src: url("../../../public/Lato-Regular.ttf");
}

@font-face {
    font-family: Lato-Bold;
    src: url("../../../public/Lato-Bold.ttf");
}


@import "header.scss";
@import "footer.scss";
@import "home.scss";
@import "registration.scss";
@import "thank-you.scss";
.footer {
    background-color: #000;
    padding: 26px 0;
    text-align: center;

    .footer-inner {
        .copy-right-text {
            p {
                margin-bottom: 0;
                color: #fff;
                font-family: Lato-Bold;
                font-size: 14px;
                line-height: 28px;
                padding: 15px 0;
            }
        }

        .loyalty-text {
            color: transparent !important;
            overflow: hidden;
            font-weight: 600;
            font-family: none;
            position: relative;
            text-align: center;
            background: linear-gradient(to right, #FFF 40%, #BC67FF 60%, #E82170 80%, #FFF 100%);
            background-size: 200% auto;
            background-clip: text;
            -text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: shine 8s linear infinite;

            @keyframes shine {
                0% {
                    background-position: 200% center;
                }
            }
            a {
                text-decoration: none;
    
                &:hover {
                    color: #c4c4c4;
                }
            }
    
            em {
                display: inline-block;
                margin: 0 .3rem;
                width: 1rem;
                width: 24px;
                height: 24px;
    
                i {
                    animation: plus-heart 1s infinite;
                    -webkit-animation: plus-heart 1s infinite;
                    width: 13px;
                    height: 14px;
                }
    
                .fa-spinner:before {
                    content: "\f004";
                    background: linear-gradient(to right, #FFF 40%, #BC67FF 60%, #E82170 80%, #FFF 100%);
                    background-size: 200% auto;
                    background-clip: text;
                    animation: shine 8s linear infinite;
                }
            }
        }
    }
}

@keyframes plus-heart {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
    }

}
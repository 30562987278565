.thank-you {
    padding: 42px 0;
    background-color: #F2F2F2;

    .thank-you-inner {
        text-align: center;

        img {
            margin-bottom: 20px;
        }

        .thank-you-text {
            padding: 0 19rem;

            h3 {
                font-size: 28px;
                line-height: 40px;
                color: #0C646E;
                font-family: Co Headline Bold;
                margin-bottom: 20px;
            }

            p {
                font-size: 18px;
                line-height: 28px;
                color: #444444;
                font-family: Lato-Regular;
                margin-bottom: 24px;
            }
        }
    }

    .thank-you-box {
        background-color: #0C646E;
        border-radius: 25px;
        box-shadow: 0px 3px 6px #00000029;
        text-align: center;
        padding: 28px;
        width: 663px;
        margin: 0 auto;

        .thank-you-content {
            h2 {
                font-size: 24px;
                line-height: 40px;
                color: #FFFFFF;
                font-family: Co Headline Bold;
                margin-bottom: 10px;
            }

            p {
                font-size: 18px;
                line-height: 28px;
                color: #FFFFFF;
                font-family: Lato-Regular;
                margin-bottom: 16px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .thank-you {
        padding: 20px 0;

        .thank-you-inner {
            .thank-you-text {
                padding: 0 0;

                h3 {
                    font-size: 21px;
                }

                p {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 600;
                }
            }

            img {
                margin-bottom: 13px;
                width: 96px;
            }
        }


        .thank-you-box {
            width: 100%;
            padding: 20px;
            .thank-you-content {
                h2 {
                    font-size: 14px;
                    font-family: Co Headline;
                    margin-bottom: 5px;
                    line-height: 18px;
                }
    
                p {
                    font-size: 12px;
                    line-height: 18px;
                    margin-bottom: 7px;
                }
            }
    
            .social-icons {
                img {
                    width: 47px;
                }
            }
        }

    }
}

@media screen and (max-width: 767px) {
    .footer {
        .footer-inner {
            .copy-right-text {
                p {
                    padding: 10px 0;
                }
            }
        }
    }
}